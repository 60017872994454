export default {
    poolContractMainnetAddr:'cfx:achb1yjn27wsndsafx927c3xaems88bkzp9pdkvrbw',
    poolContractTestnetAddr:'cfx:achb1yjn27wsndsafx927c3xaems88bkzp9pdkvrbw',
    modelContractMainnetAddr:'0x5576e98Fd35a6a4616ddA95Bf0F6E649f238a775',
    modelContractTestnetAddr:'0x5576e98Fd35a6a4616ddA95Bf0F6E649f238a775',
    tokenContractMainnetAddr:'0x8CBB19d5a1eBB5436795d96a9ED6D9fa0D4D0e8e',
    tokenContractTestnetAddr:'0x8CBB19d5a1eBB5436795d96a9ED6D9fa0D4D0e8e',
    espaceTestChainId:71,
    espaceMainChainId:1030,
    cspaceTestChainId:1,
    cspaceMainChainId:1029,
    testnet:'testnet',
    mainnet:'mainnet',
    cspace:'cspace',
    espace:'espace',
    unknown:'unknown',
    walletFluent:'Fluent',
    walletOneKey:'OneKey',
    utokenAddress:'0xfe97e85d13abd9c1c33384e796f10b73905637ce',
    cscanAddress:"https://www.confluxscan.net/pos/accounts/",
    farmAddress:"0x8B1d603Cf10Ed2D17e213F2Ad51bD133683053b1",
    swappiAddress:"0x62b0873055bf896dd869e172119871ac24aea305",
    cfl2cfxAddress:"0x9dff130a0e4540f810bc57093c345da719b91354",
    cfx2ustdAddress:"0x8fcf9c586d45ce7fcf6d714cb8b6b21a13111e0b",
    lpAddress:"0x943b9B4718826eA7023f79C66E0d40BeBbcdE22f",
    cflsMiner:"0x681d7125504afcdd2aa554078774c2655ea1c354",
    cfls:"0x513fbd346fa349f59ae355b58697a8d09192456d",
    lpReward:"0x4621eFf8aDf3c69e8A461d955c36c1835a5c1D61",
    lpmAddress:"0x207F246EE6F040030B918A89c3FBFC5fC8DD3e21",
    mit2cfxAddress:"0x5e6228978ae9585ef0f151c7f6beffe893053633",
}