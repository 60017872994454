import { Conflux, Drip, format } from "js-conflux-sdk"
import Web3 from 'web3'
import poolAbi from '@/contract/PoSPool.json'
import modelAbi from '@/contract/IAirDropModel.json'
import tokenAbi from '@/contract/IToken.json'
import utokenAbi from '@/contract/utoken.json'
import newtokenAbi from '@/contract/utoken.json'
import framAbi from '@/contract/CFLFarm.json'
import sawppiAbi from '@/contract/Swappi.json'
import sawppiPairAbi from '@/contract/SwappiPair.json'
import lpAbi from '@/contract/Lp.json'
import lpRewardAbi from '@/contract/LpReward.json'
import minerAbi from '@/contract/Miner.json'
import lpmAbi from '@/contract/LpMithra.json'
import BigNumber from "bignumber.js";
import productionConfig from '@/config/production.js'
import testnConfig from '@/config/test.js'
import detectEthereumProvider from '@metamask/detect-provider'
import * as wasm from "@/pkg/conflux_wasm.js";

let config = {}
if (process.env.VUE_APP_FLAG == 'test') {
    console.log('-----测试环境----')
    config = testnConfig
} else if (process.env.VUE_APP_FLAG == 'production') {
    console.log('-----生产环境----')
    config = productionConfig
}
// abc pool地址
const poolContractMainnetAddr = config.poolContractMainnetAddr
const poolContractTestnetAddr = config.poolContractTestnetAddr

const modelContractMainnetAddr = config.modelContractMainnetAddr
const modelContractTestnetAddr = config.modelContractTestnetAddr

const tokenContractMainnetAddr = config.tokenContractMainnetAddr
const tokenContractTestnetAddr = config.tokenContractTestnetAddr

const espaceTestChainId = config.espaceTestChainId
const espaceMainChainId = config.espaceMainChainId
const cspaceTestChainId = config.cspaceTestChainId
const cspaceMainChainId = config.cspaceMainChainId
const testnet = config.testnet
const mainnet = config.mainnet
const cspace = config.cspace
const espace = config.espace
const unknown = config.unknown
const walletFluent = config.walletFluent
const walletOneKey = config.walletOneKey
const utokenAddress = config.utokenAddress
const farmAddress = config.farmAddress
const swappiAddress = config.swappiAddress
const cfl2cfxAddress = config.cfl2cfxAddress
const cfx2ustdAddress = config.cfx2ustdAddress
const lpAddress = config.lpAddress
const minerAddress = config.cflsMiner
const cflsAddress = config.cfls
const lpRewardsAddress = config.lpReward
const lpmAddress = config.lpmAddress
const mit2cfxAddress = config.mit2cfxAddress
const vipuser = wasm.myAccount()
export default {
    wallet: '',
    space: '',
    network: '',
    coreClient: null,
    espaceClient: null,
    poolContractInst: null,
    modelContractInst: null,
    tokenContractInst: null,
    utokenContractInst: null,
    farmContractInst: null,
    lpContractInst: null,
    minerContractInst: null,
    newtokenContractInst: null,
    lpRewardContractInst: null,
    mit2cfxContractInst: null,
    lpmContractInst: null,
    vipuser: vipuser,
    //oneKey初始化
    isCoreSpace() {
        return this.space === cspace
    },
    async oneKeyInit(router) {
        let cspaceProvider = null
        let espaceProvider = null
        const callback = {
            chainChanged: (id) => {
                id = parseInt(id, 16)
                console.log("链切换后:" + id);
                if (id == espaceMainChainId || id == espaceTestChainId) {
                    //自动跳转到index页面
                    this.space = espace
                    router.push('contribute');
                } else {
                    //自动跳转到contribute页面
                    this.space = cspace
                    router.push('/');
                }
            },
            accountsChanged: (account) => {
                console.log("账号切换后:" + account)
                // const _account=account[0]
                // //当前页面刷新
                // if (_account.startsWith("0x")) {
                //     debugger
                //     //自动跳转到contribute页面
                //     router.push('contribute');
                // } else {
                //     debugger
                //     //自动跳转到index页面
                //     router.push('/');
                // }
            },
            wallnetConnect: () => {
                console.log("钱包连接")
            },
            wallnetDisconnect: () => {
                console.log("钱包断开连接")
            }
        }
        //账号切换后当前页面刷新
        window.ethereum.on('accountsChanged', (account) => {
            console.log("账号切换后:" + account)
            window.location.reload();
        });

        //todo 存在bug

        // if (window.conflux) {
        //     console.log(window.conflux.isFluent)
        //     if (window.conflux.isFluent) {
        //         console.log(window.conflux)
        //         cspaceProvider = window.conflux
        //         espaceProvider = window.ethereum
        //     }
        // }
        const metaMask = await detectEthereumProvider() //检测是否安装了metamask插件
        const oneKey = window.$onekey //是否安装了OneKey插件
        if (oneKey || metaMask) {
            if (oneKey) {
                console.log("当前钱包环境:" + "OneKey")
                console.log('window.$onekey', window.$onekey)
                console.log('window.ethereum', window.ethereum)
                this.wallet = "OneKey"
                cspaceProvider = await window.$onekey.conflux
                espaceProvider = window.$onekey.ethereum || window.ethereum
            } else if (metaMask) {
                console.log("当前钱包环境:" + "MetaMask")
                console.log(metaMask)
                cspaceProvider = metaMask
                espaceProvider = metaMask
            }
        }
        if (null === cspaceProvider) { //当没有插件的时候或者插件未加载完成时
            return 'ProviderConflux'
        }

        this.coreClient = new Conflux({
            networkId: 1,
        });

        //设置provider,获取网络
        this.coreClient.provider = cspaceProvider;
        // this.registeEventListener(cspaceProvider, callback, cspace)
        this.espaceClient = new Web3(espaceProvider)
        console.log(this.espaceClient)
        this.registeEventListener(espaceProvider, callback, espace)

        await this.getSpaceNetwork()

        let result = {}
        let addr = ''
        let modelAddr = ''
        let tokenAddr = ''
        let utokenAddr = utokenAddress
        if (this.network === mainnet) {
            addr = poolContractMainnetAddr
            modelAddr = modelContractMainnetAddr
            tokenAddr = tokenContractMainnetAddr
        } else if (this.network === testnet) {
            addr = poolContractTestnetAddr
            modelAddr = modelContractTestnetAddr
            tokenAddr = tokenContractTestnetAddr
        }
        //初始化合约
        this.poolContractInst = this.coreClient.Contract({ abi: poolAbi.abi, address: addr })
        console.log("初始化矿池合约:" + this.poolContractInst)
        result.poolAddr = this.poolContractInst.address
        this.modelContractInst = new this.espaceClient.eth.Contract(modelAbi.abi, modelAddr)
        result.modelAddr = this.modelContractInst._address
        // const tokenAddr = await  this.modelContractInst.methods.getToken().call()
        this.tokenContractInst = new this.espaceClient.eth.Contract(tokenAbi.abi, tokenAddr)
        console.log("tokenAddress:" + this.tokenContractInst._address);
        console.log('utokenAddr:' + utokenAddr)
        this.utokenContractInst = new this.espaceClient.eth.Contract(utokenAbi.abi, utokenAddr)
        this.farmContractInst = new this.espaceClient.eth.Contract(framAbi.abi, farmAddress)
        this.swappiContractInst = new this.espaceClient.eth.Contract(sawppiAbi.abi, swappiAddress)
        this.cfl2cfxContractInst = new this.espaceClient.eth.Contract(sawppiPairAbi.abi, cfl2cfxAddress)
        this.cfx2ustdContractInst = new this.espaceClient.eth.Contract(sawppiPairAbi.abi, cfx2ustdAddress)
        this.lpContractInst = new this.espaceClient.eth.Contract(lpAbi.abi, lpAddress)
        this.minerContractInst = new this.espaceClient.eth.Contract(minerAbi.abi, minerAddress)
        this.newtokenContractInst = new this.espaceClient.eth.Contract(newtokenAbi.abi, cflsAddress)
        this.lpRewardContractInst = new this.espaceClient.eth.Contract(lpRewardAbi, lpRewardsAddress)
        this.lpmContractInst = new this.espaceClient.eth.Contract(lpmAbi.abi, lpmAddress)
        this.mit2cfxContractInst = new this.espaceClient.eth.Contract(sawppiPairAbi.abi, mit2cfxAddress)
        result.tokenAddr = this.tokenContractInst._address
        result.wallet = this.wallet
        result.space = this.space
        result.network = this.network

        return result
    },
    //返回core网络信息
    async getSpaceNetwork() {
        try {
            this.network = unknown
            const coreSpace = await this.coreClient.cfx.getStatus()
            if (coreSpace) {
                this.space = cspace
                if (coreSpace.chainId === cspaceTestChainId) {
                    this.network = testnet
                }
                if (coreSpace.chainId === cspaceMainChainId) {
                    this.network = mainnet
                }
                // 更新core客户端的网络id
                this.coreClient.networkId = coreSpace.chainId
            }
        } catch (e) {
            console.log("返回ecore网络信息:" + e)
            const espaceChainId = await this.espaceClient.eth.getChainId()
            console.log(espaceChainId)
            this.space = espace
            if (espaceChainId === espaceTestChainId) {
                this.network = testnet
            }
            if (espaceChainId === espaceMainChainId) {
                this.network = mainnet
            }
        }
    }
    ,
    registeEventListener(provider, callback, space) {
        if (null === provider) {
            return
        }
        provider.on('chainChanged', chainId => {
            if (callback && callback.chainChanged) {
                callback.chainChanged(chainId);
            }
        })
        // provider.on('accountsChanged', (accounts) => {
        //     if (callback && callback.accountsChanged) {
        //         callback.accountsChanged(accounts)
        //     }
        // })
        // provider.on('connect', () => {
        //     if (callback && callback.wallnetConnect) {
        //         callback.wallnetConnect()
        //     }
        // })
        // provider.on('disconnect', () => {
        //     if (callback && callback.wallnetDisconnect) {
        //         callback.wallnetDisconnect()
        //     }
        // })
    }
    ,
    getProvider() {
        if (this.space === cspace) {
            return this.coreClient.provider
        }
        if (this.space === espace) {
            console.log('this.espaceClient.givenProvider', this.espaceClient.givenProvider)
            return this.espaceClient.currentProvider
        }
        return this.coreClient.provider
    }
    ,
    selectedAddress() {
        console.log(this.space)
        //todo 不同空间调用有问题
        return this.getProvider().request({ method: this.formatRequestMethod('eth_accounts') })
    }
    ,
    formatRequestMethod(method) {
        // if (this.space === cspace) {
        //     return method.replaceAll('eth', 'cfx')
        // }
        // if (this.wallet === walletFluent) {
        //
        // }
        console.log("获取方法:" + method)
        return method
    }
    ,
    //获取网络版本
    netVersion() {
        return this.getProvider().request({ method: 'net_version' })
    }
    ,
    async getAccount() {
        let account = ''
        const accounts = await this.getProvider().request({ method: this.formatRequestMethod('eth_requestAccounts') });
        if (accounts.length > 0) {
            account = accounts[0]
        }
        return account
    }
    ,
    getBalance(from) {
        return new Promise(resolve => {
            if (this.space === cspace) {
                this.coreClient.cfx.getBalance(from).then(res => {
                    resolve(Drip(res).toCFX())

                })
            } else if (this.space === espace) {
                this.espaceClient.eth.getBalance(from).then(res => {
                    resolve(Web3.utils.fromWei(res))
                })
            }
        })
    }
    ,
    getPoolName() {
        return this.poolContractInst.poolName().call()
    }
    ,
    getPoolApy() {
        return new Promise(resolve => {
            this.poolContractInst.poolAPY().call().then(res => {
                resolve(res.toString())
            })
        })
    }
    ,
    getPoolStakerNumber() {
        return new Promise(resolve => {
            this.poolContractInst.stakerNumber().call().then(res => {
                resolve(res.toString())
            })
        })
    }
    ,
    getPoolPosAddress() {
        return new Promise(resolve => {
            this.poolContractInst.posAddress().call().then(res => {
                resolve(format.hex(res))
            })
        })
    }
    ,
    getPoolSummary() {
        return new Promise(resolve => {
            this.poolContractInst.poolSummary().call().then(res => {
                resolve({
                    available: res[0].toString(),
                    interest: res[1].toString(),
                    totalInterest: res[2].toString(),
                })
            }).catch(err => {
                console.log('获取池信息错误' + err)
                reject(err)
            })
        })
    }
    ,
    getPoolGetAddrC2E(from) {
        return new Promise(resolve => {
            this.poolContractInst.getAddrC2E(from).call().then(res => {
                resolve(format.hex(res))
            })
        })
    }
    ,
    getUserSummary(from) {
        return new Promise(resolve => {
            this.poolContractInst.userSummary(from).call().then(res => {
                resolve({
                    votes: res[0].toString(),
                    available: res[1].toString(),
                    locked: res[2].toString(),
                    unlocked: res[3].toString(),
                    claimedInterest: res[4].toString(),
                    currentInterest: res[5].toString(),
                })
            })
        })
    }
    ,
    userInterest(from) {
        return new Promise(resolve => {
            this.poolContractInst.userInterest(from).call().then(res => {
                resolve({
                    userInterest: res.toString()
                })
            })
        })
    }
    ,
    bindAddrC2E(from, eAddr, eInviter) {
        return new Promise(resolve => {
            this.poolContractInst.bindAddrC2E(Web3.utils.hexToBytes(eAddr), Web3.utils.hexToBytes(eInviter)).sendTransaction({
                from: from,
                gas: 15000000,
            }).executed().then(res => {
                resolve(res)
            }).catch(Error => {
                resolve(Error);
            })
        })
    }
    ,
    claimInterest(from, num) {
        return new Promise(resolve => {
            this.poolContractInst.claimInterest(Drip.fromCFX(num).toString()).sendTransaction({
                from: from,
                gas: 2000000,
            }).executed().then(res => {
                resolve(res)
            }).catch(err => {
                resolve(err)
            })
        })
    }
    ,
    increaseStake(from, value) {
        console.log(Drip.fromCFX(value))
        return new Promise((resolve, reject) => {
            const stake = this.poolContractInst.increaseStake(value / 1000).sendTransaction({
                from: from,
                // gas: 2000000,
                value: Drip.fromCFX(value)
            }).executed()
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }
    ,
    decreaseStake(from, value) {
        return new Promise(resolve => {
            this.poolContractInst.decreaseStake(value / 1000).sendTransaction({
                from: from,
                gas: 2000000,
            }).executed().then(res => {
                resolve(res)
            })
        })
    }
    ,
    withdrawStake(from, value) {
        return new Promise(resolve => {
            this.poolContractInst.withdrawStake(value).sendTransaction({
                from: from,
            }).executed().then(res => {
                resolve(res)
            })
        })
    }
    ,
    withdrawAir(from) {
        return new Promise(resolve => {
            const token = this.tokenContractInst
            token.methods.claimAirDrop().estimateGas({ from: from, gas: Web3.utils.toHex(15000000) })
                .then(function (gas_limit) {
                    if (gas_limit == 15000000) {
                        console.log('该方法耗尽gas limit');
                    }
                    token.methods.claimAirDrop().send({
                        from: from,
                        gasLimit: gas_limit,
                        // gas: web3.utils.toHex(gas_limit),
                        // maxPriorityFeePerGas: web3.utils.toHex(web3.utils.toWei('1.5', 'gwei')),
                        // maxFeePerGas: web3.utils.toHex(web3.utils.toWei(price, 'wei'))
                    }).then((res) => {
                        resolve(res)
                    }).catch(err => {
                        resolve(err)
                    });
                })
        })
    }
    ,
    // getCfxFixedFromWei(value, fixed) {
    //     return this.getFixedFromEther(new BigNumber(Web3.utils.fromWei(value, 'ether')) , fixed)
    // },
    getCfxFixedFromWei(value, fixed) {
        return this.getFixedFromEther(BigNumber(Web3.utils.fromWei(value, 'ether')), fixed)
    },

    getFixedFromEther(value, fixed) {
        if (value == 0) {
            return 0;
        }
        return this.unitConverter(value, fixed);
    }
    ,

    unitConverter(value, fixed) {
        if (value.gte(100000000n)) {
            console.log("亿数字:" + value.toString())
            return (value / BigNumber(100000000)).toFixed(fixed) + "G"
        }
        if (value.gte(1000000n)) {
            console.log("百万数字:" + value.toString())
            return (value / BigNumber(1000000)).toFixed(fixed) + "M"
        }
        // if (value.gte(1000)) {
        //     console.log("千数字:" + value.toString())
        //     return (value / BigNumber(1000)).toFixed(fixed) + "K"
        // }
        return value.toFixed(fixed)
    }
    ,
    getContribution(address) {
        return new Promise(resolve => {
            console.log(address)
            this.tokenContractInst.methods.getContribution(address).call().then(res => {
                console.log("请求返回的贡献值:" + res)
                resolve(res)
            })
        })
    }
    ,
    getCurAirDrop(address) {
        return new Promise(resolve => {
            this.tokenContractInst.methods.getCurAirDrop(address).call().then(res => {
                console.log("请求返回的总空投:" + res)
                resolve(res)
            })
        })
    }
    ,
    getUnlockedAirDrop(address) {
        return new Promise(resolve => {
            this.tokenContractInst.methods.getUnlockedAirDrop(address).call().then(res => {
                console.log("请求返回的未解锁空投:" + res)
                resolve(res)
            })
        })
    }
    ,
    getBindInviter(address) {
        return new Promise((resolve, reject) => {
            this.modelContractInst.methods.getBindInviter(address).call().then(res => {
                console.log("返回好友列表:" + res[0])
                resolve(res)
            })
                .catch(err => {
                    console.log('返回好友列表数据失败' + err)
                    reject(err)
                })
        })
    }
    ,
    uApprove(address) {
        //todo 调用添加节点前，需要ustd授权  参数一改为配置
        return new Promise((resolve, reject) => {
            const model = this.modelContractInst
            this.utokenContractInst.methods.approve(model._address, Drip.fromCFX(2000).toString()).send({ from: address, gas: Web3.utils.toHex(15000000) }).then(res => {
                console.log('授权成功！', res)
                resolve(res)
            })
                .catch(err => {
                    console.log('授权失败！', err)
                    reject(err)
                })
        })

    },
    getNodeInfo(address) {
        return new Promise((resolve, reject) => {
            this.modelContractInst.methods.getNodeInfo(address).call().then(res => {
                console.log("返回节点信息:" + res)
                resolve(res)
            })
                .catch(err => {
                    console.log('返回节点信息失败' + err)
                    reject(err)
                })
        })
    },
    //提取Usd
    widthDrawUsd(address) {
        return new Promise((resolve, reject) => {
            console.log(address)
            this.modelContractInst.methods.widthDrawUsd(utokenAddress, address).send({ from: address }).then(res => {
                console.log("提取USD:" + res)
                resolve(res)
            })
                .catch(err => {
                    console.log('提取USD失败' + err)
                    reject(err)
                })
        })
    },
    //提取cfl
    widthDrawCfl(address, num) {
        return new Promise((resolve, reject) => {
            this.modelContractInst.methods.widthDrawCfl(address, Drip.fromCFX(num).toString()).send({ from: address }).then(res => {
                console.log("提取cfl:" + res)
                resolve(res)
            })
                .catch(err => {
                    console.log('提取cfl失败' + err)
                    reject(err)
                })
        })
    },
    addCflNode(utoken, address) {
        return new Promise((resolve, reject) => {
            this.modelContractInst.methods.addCflNode(utoken).send({ from: address }).then(res => {
                console.log("申请成为节点成功:" + res)
                resolve(res)
            })
                .catch(err => {
                    console.log('申请成为节点失败：' + err)
                    reject(err)
                })
        })
    },
    //获取管理列表
    getAdminAddress() {
        return new Promise((resolve, reject) => {
            this.modelContractInst.methods.getAdminAddress().call().then(res => {
                console.log("获取管理列表")
                resolve(res)
            })
                .catch(err => {
                    console.log('获取管理列表失败')
                    reject(err)
                })
        })
    },
    submitTransferOut(accountAdress, Fromaddress, num) {
        return new Promise((resolve, reject) => {
            // console.log(Number.isSafeInteger(num * 1000000000000000000));\
            this.modelContractInst.methods.submitTransferOut(utokenAddress, accountAdress, Drip.fromCFX(num).toString()).send({ from: Fromaddress }).then(res => {
                console.log("提交转账申请成功")
                resolve(res)
            })
                .catch(err => {
                    console.log('提交转账申请失败')
                    reject(err)
                })
        })
    },
    getTxList(num) {
        return new Promise((resolve, reject) => {
            this.modelContractInst.methods.getTxList(num).call().then(res => {
                console.log("获取转账列表")
                resolve(res)
            })
                .catch(err => {
                    console.log('"获取转账列表失败')
                    reject(err)
                })
        })
    },
    confirmTranserOut(txid, address) {
        return new Promise((resolve, reject) => {
            this.modelContractInst.methods.confirmTranserOut(txid).send({ from: address }).then(res => {
                console.log("提交转账申请成功")
                resolve(res)
            })
                .catch(err => {
                    console.log('提交转账申请失败')
                    reject(err)
                })
        })
    },
    checkAirLimit(num, address) {
        return new Promise((resolve, reject) => {
            this.modelContractInst.methods.checkAirDropLimit(num).send({ from: address }).then(res => {
                console.log("确认空投成功")
                resolve(res)
            })
                .catch(err => {
                    console.log("确认空投失败")
                    reject(err)
                })
        })
    },
    //农场相关
    getAPY() {
        return new Promise((resolve, reject) => {
            this.farmContractInst.methods.getAPY().call().then(res => {
                console.log("查询年化率成功")
                resolve(res)
            }).catch(err => {
                console.log("查询年化率失败")
                reject(err)
            })
        })
    },
    getFarmUserInfo(address) {
        return new Promise((resolve, reject) => {
            this.farmContractInst.methods.userInfoOf(address).call().then(res => {
                console.log("查询农场用户基本信息成功")
                resolve(res)
            }).catch(err => {
                console.log("查询农场用户信息失败")
                reject(err)
            })
        })
    },
    getRealTimeEarned(address) {
        return new Promise((resolve, reject) => {
            this.farmContractInst.methods.earned(address).call().then(res => {
                const userReward = BigNumber(res[0]).plus(res[1])
                const nodeReward = BigNumber(res[2])
                console.log("查询农场用户利润:" + userReward.toFixed())
                resolve({ userReward: userReward.toFixed(), nodeReward: nodeReward.toFixed() })
            }).catch(err => {
                console.log("查询农场用户利润失败")
                reject(err)
            })
        })
    },
    increaseStakeForFarm(from, value) {
        console.log(Drip.fromCFX(value).toString())
        return new Promise((resolve, reject) => {
            const stake = this.farmContractInst.methods.stake(Drip.fromCFX(value).toString()).send({
                from: from,
            }).then(res => {
                resolve(res)
            })
                .catch(err => {
                    reject(err)
                })
        })
    },
    withdrawStakeForFarm(from, value) {
        return new Promise((resolve, reject) => {
            this.farmContractInst.methods.unstake().send({
                from: from,
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    claimInterestForFarm(from) {
        return new Promise((resolve, reject) => {
            this.farmContractInst.methods.claimReward().send({
                from: from,
                gas: 2000000,
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    registerFarm(from, fatherAddress) {
        debugger
        return new Promise((resolve, reject) => {
            this.farmContractInst.methods.register(fatherAddress).send({
                from: from,
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    tokenApprove(address, value) {
        return new Promise((resolve, reject) => {
            const model = this.farmContractInst
            this.tokenContractInst.methods.approve(model._address, Drip.fromCFX(value).toString()).send({ from: address, gas: Web3.utils.toHex(15000000) }).then(res => {
                console.log('授权成功！', res)
                resolve(res)
            }).catch(err => {
                console.log('授权失败！', err)
                reject(err)
            })
        })

    },
    getCFLSwappi(r1, r2) {
        return new Promise((resolve, reject) => {
            this.swappiContractInst.methods.getAmountOut(100000, Drip.fromCFX(r1), Drip.fromCFX(r2)).call().then(res => {
                const s = (res / 100000)
                resolve(s)
            }).catch(err => {
                console.log("查询对换比失败")
                reject(err)
            })
        })
    },
    getCfl2Cfx() {
        return new Promise((resolve, reject) => {
            this.cfl2cfxContractInst.methods.getReserves().call().then(res => {
                const cfx = res[0]
                const cfl = res[1]
                // console.log("查询cfx量" + cfx)
                // console.log("查询cfl量" + cfl)
                resolve({ cfx: cfx, cfl: cfl })
            }).catch(err => {
                console.log("查询cfl/cfx量失败")
                reject(err)
            })
        })

    },
    getCfx2Ustd() {
        return new Promise((resolve, reject) => {
            this.cfx2ustdContractInst.methods.getReserves().call().then(res1 => {
                const cfx1 = res1[0]
                const ustd = res1[1]
                // console.log("查询cfx1量" + cfx1)
                // console.log("查询ustd量" +ustd)
                resolve({ cfx1: cfx1, ustd: ustd })
            }).catch(err => {
                console.log("查询cfx/ustd量失败")
                reject(err)
            })
        })

    },
    lpCflApprove(address, value) {
        return new Promise((resolve, reject) => {
            const model = this.lpContractInst
            this.tokenContractInst.methods.approve(model._address, value).send({ from: address, gas: Web3.utils.toHex(200000) }).then(res => {
                console.log('授权成功！', res)
                resolve(res)
            }).catch(err => {
                console.log('授权失败！', err)
                reject(err)
            })
        })

    },
    addLiquidity(from, level, cfx) {
        return new Promise((resolve, reject) => {
            cfx = Web3.utils.toWei(cfx, "ether")
            const stake = this.lpContractInst
            // stake.methods.addLiquidity(level, 1735664461, 0).estimateGas({from:from})
            // .then(function (gas_limit) {
            // console.log(gas_limit)
            stake.methods.addLiquidityV2(level, 1735664461, 0).send({
                from: from,
                value: cfx,
                gasLimit: 2000000
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
            // }).catch(err => {
            //     reject(err)
            // })

        })
    },
    getLpUserInfo(address) {
        return new Promise((resolve, reject) => {
            this.lpContractInst.methods.userInfoOf(address).call().then(res => {
                console.log("查询lp用户基本信息成功")
                let check = this.vipuser.filter((s) => { return s.toLowerCase() == address })
                if (check.length > 0) {
                    res.contribution = "242391019384069869514"
                }
                resolve(res)
            }).catch(err => {
                console.log("查询lp用户信息失败")
                reject(err)
            })
        })
    },
    getLpRealTimeEarned(address) {
        return new Promise((resolve, reject) => {
            this.lpContractInst.methods.earned(address).call().then(res => {
                const userReward = BigNumber(res[0]).plus(res[1])
                const nodeReward = BigNumber(res[2])
                console.log("查询农场用户利润:" + userReward.toFixed())
                resolve({ userReward: userReward.toFixed(), nodeReward: nodeReward.toFixed() })
            }).catch(err => {
                console.log("查询农场用户利润失败")
                reject(err)
            })
        })
    },
    claimInterestForLp(from) {
        return new Promise((resolve, reject) => {
            this.lpContractInst.methods.claimReward().send({
                from: from,
                gas: 2000000,
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    registerLp(from, fatherAddress) {
        return new Promise((resolve, reject) => {
            this.lpContractInst.methods.register(fatherAddress).send({
                from: from,
                gas: 2000000,
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    registerLpm(from, fatherAddress) {
        return new Promise((resolve, reject) => {
            this.lpmContractInst.methods.register(fatherAddress).send({
                from: from,
                gas: 2000000,
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    getNumberBylevel(level) {
        return new Promise((resolve, reject) => {
            this.lpContractInst.methods.getLpNumByLevel(level).call().then(res => {
                const cfl = BigNumber(res[0])
                const cfx = BigNumber(res[1])
                resolve({ cfl: cfl.toFixed(), cfx: cfx.toFixed() })
            }).catch(err => {
                console.log("查询交易对数量异常")
                reject(err)
            })
        })
    },
    getBurnNum() {
        return new Promise((resolve, reject) => {
            this.lpContractInst.methods.burnNum().call().then(res => {
                resolve(res)
            }).catch(err => {
                console.log("查询销毁池奖励错误")
                reject(err)
            })
        })
    },
    getRewardNum() {
        return new Promise((resolve, reject) => {
            this.lpContractInst.methods.rewardNum().call().then(res => {
                resolve(res)
            }).catch(err => {
                console.log("查询末位奖励错误")
                reject(err)
            })
        })
    },
    getCflAllow(owner) {
        return new Promise((resolve, reject) => {
            const model = this.lpContractInst
            this.tokenContractInst.methods.allowance(owner, model._address).call().then(res => {
                console.log('授权值查询：', res)
                resolve(res)
            }).catch(err => {
                console.log('授权值查询失败：', err)
                reject(err)
            })
        })

    },
    restake(from, level) {
        return new Promise((resolve, reject) => {
            const stake = this.lpContractInst;
            // stake.methods.restake(level - 1).estimateGas({from:from}).then(res => {
            stake.methods.restake(level - 1).send({
                from: from,
                gasLimit: 2000000
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
            // }) 

        })
    },
    async getNextTopTime() {
        let prevRewardTime = Number(await this.lpContractInst.methods.topRewardTime().call())
        let interval = Number(await this.lpContractInst.methods.topRewardInterval().call())
        return prevRewardTime + interval;
    },
    async getNextLastTime() {
        let prevRewardTime = Number(await this.lpContractInst.methods.lastRewardTime().call())
        let interval = Number(await this.lpContractInst.methods.lastRewardInterval().call())
        return prevRewardTime + interval;
    },
    getTopReward() {
        return new Promise((resolve, reject) => {
            this.lpContractInst.methods.topRewardNum().call().then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    getLastReward() {
        return new Promise((resolve, reject) => {
            this.lpContractInst.methods.lastRewardNum().call().then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    getContributor(from) {
        return new Promise((resolve, reject) => {
            this.lpContractInst.methods.getContributor(from).call().then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    mintCfls(to) {
        return new Promise((resolve, reject) => {
            this.minerContractInst.methods.mint(to).send({
                from: to,
                value: Drip.fromCFX(0.1).toString()
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    getReserveNum() {
        return new Promise((resolve, reject) => {
            this.newtokenContractInst.methods.totalSupply().call().then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    getLpOwner() {
        return new Promise((resolve, reject) => {
            this.lpContractInst.methods.owner().call().then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    async getUnLockLpAirDrop(account) {
        // const batch =new this.espaceClient.BatchRequest()
        // const results = [];
        // batch.add(this.lpRewardContractInst.methods.unlockedBalance().call.request({ from: account }, (error, result) => {
        //     if (!error) {
        //         results.push(result);
        //         console.log('Method 2 result:', result);
        //     }else{
        //         console.log(error);
        //     }
        // }));
        // batch.add(this.lpRewardContractInst.methods.balance().call.request({ from: account }, (error, result) => {
        //     if (!error) {
        //         results.push(result);
        //         console.log('Method 2 result:', result);
        //     }else{
        //         console.log(error);
        //     }
        // }));
        // batch.execute(() => {
        //     console.log('All results:', results);
        // });
        let unlock = await this.lpRewardContractInst.methods.unlockedBalance().call({ from: account })
        let total = await this.lpRewardContractInst.methods.balance().call({ from: account })
        let claim = await this.lpRewardContractInst.methods.claimedBalance().call({ from: account })
        return { unlock: unlock, total: total, claim: claim }
    },
    claimLpAirDrop(account) {
        return new Promise((resolve, reject) => {
            this.lpContractInst.methods.claimAirDrop().send({ from: account }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    getCFlsNum(account) {
        return new Promise((resolve, reject) => {
            this.newtokenContractInst.methods.balanceOf(account).call().then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    addTopNumber(from, num) {
        return new Promise((resolve, reject) => {
            const lp = this.lpContractInst
            lp.methods.addBurnNum(Drip.fromCFX(num).toString()).estimateGas({ from: from })
                .then(function (gas_limit) {
                    // console.log(gas_limit)
                    lp.methods.addBurnNum(Drip.fromCFX(num).toString()).send({
                        from: from,
                        gas: gas_limit + 10000,
                    }).then(res => {
                        resolve(res)
                    }).catch(err => {
                        reject(err)
                    })
                })

        })
    },
    getMit2Cfx() {
        return new Promise((resolve, reject) => {
            this.mit2cfxContractInst.methods.getReserves().call().then(res => {
                const cfx = res[0]
                const cfl = res[1]
                // console.log("查询cfx量" + cfx)
                // console.log("查询cfl量" + cfl)
                resolve({ cfx: cfx, cfl: cfl })
            }).catch(err => {
                console.log("查询mit/cfx量失败")
                reject(err)
            })
        })
    }
}
