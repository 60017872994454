
const state = {
  cfxToolInit: false,
  espaceInit: false,
  address: '',
  viewAddress:'',
  // recAddress:sessionStorage.getItem('recAddress')
}

const mutations = {
  SET_CFX_TOOL_INIT: (state, cfxToolInit) => {
    state.cfxToolInit = cfxToolInit
  },
  SET_ESPACE_INIT: (state, espaceInit) => {
    state.espaceInit = espaceInit
  },
  SET_ADDRESS: (state, address) => {
    state.address = address
  },
  SET_VIEWADDRESS: (state, viewAddress) => {
    state.viewAddress = viewAddress
  },
}

const actions = {
  setCfxToolInit({ commit }, cfxToolInit) {
    commit('SET_CFX_TOOL_INIT', cfxToolInit)
  },
  setEspaceInit({ commit }, espaceInit) {
    commit('SET_ESPACE_INIT', espaceInit)
  },
  setAddress({ commit }, address) {
    commit('SET_ADDRESS', address)
  },
  setViewAddress({ commit }, viewAddress) {
    commit('SET_VIEWADDRESS', viewAddress)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
